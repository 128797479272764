button,
.btn {
  @include pxRem(padding, 10 20);
  @include pxRem(border-radius, 8);
  font-size: map-get($font-size, l);
  border: 1px solid map-get($color, border-gray);
  box-shadow: inset 0 -3px 8px map-get($color, border-gray);
  background-color: map-get($color, white);
  color: map-get($color, black-b);
  display: inline-block;
  font-family: $font-japan;
  font-weight: 400;
  text-decoration: none;

  &-primary {
    background-color: map-get($color, primary);
    border: none;
  }

  &.btn-block {
    width: 100%;
  }
  &:focus {
    outline: 0;
  }
  &.btn-special {
    border: none;
    background-color: map-get($color, yellow-a);
    border-radius: 42px;
    box-shadow: 2px 4px map-get($color, blue-d);
    .main-text {
      font-size: map-get($font-size, xl);
      font-weight: 500;
    }
    .sub-text {
      @include pxRem(font-size, 15);
    }
  }

  &.btn-answer {
    width: 100%;
    box-shadow: none;
    border: none;
  }

  &-loadmore {
    @include pxRem(padding, 16 20);
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: map-get($color, grey-k);
    box-shadow: none;
    border: none;
    border-radius: 0 0 10px 10px;

    img {
      width: 14px;
      margin-right: 14px;
      margin-top: 1px;
    }
  }

  &.btn-disable {
    box-shadow: unset;
    opacity: 0.7;
  }

  &-top {
    @include pxRem(padding, 0);
    @include pxRem(width, 44);
    @include pxRem(height, 44);
    @include pxRem(font-size, map-get($font-size, xs));
    @include pxRem(bottom, 32);
    @include pxRem(right, 23);
    z-index: 1;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: map-get($color, blue-d);
    color:map-get($color, grey-h);
    border: none;
    border-radius: 50%;
    box-shadow: none;
    img {
      @include pxRem(width, 20);
      @include pxRem(height, 21);
      @include pxRem(margin-right, 0);
      @include pxRem(margin-top, 0);
      @include pxRem(top, 0);
      position: absolute;
    }
    span {
    @include pxRem(padding-top, 5);
    }
  }

  &.next-btn {
    position: relative;
    &::after {
      @include pxRem(width, 11);
      @include pxRem(height, 16);
      @include pxRem(right, 12);
      content: "";
      background: url('../assets/images/next.png') no-repeat;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.fixed-section {
  @include pxRem(height, 90);
  @include pxRem(padding, 18 60);
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
  max-width: 100vw;
  width: 100%;

  .answer-btn {
    @include pxRem(padding, 12);
    background-color: map-get($color, primary);
    color: map-get($color, black-b);
    font-size: map-get($font-size, l);
    position: relative;
    width: 100%;
    box-shadow: none;
    border: none;

    .icon {
      @include pxRem(width, 20);
      @include pxRem(left, 16);
      @include transform(translateY(-50%));
      font-size: 0;
      position: absolute;
      top: 50%;
    }
  }
}

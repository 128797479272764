.not-found-page {
    @include pxRem(padding, 0 50);
    background: map-get($color, white);
    z-index: map-get($z-index, not-found);
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    .not-found-body {
        padding-bottom: 180px;
        min-height: 100vh;
        .page-title {
            @include pxRem(font-size, 36);
            @include pxRem(padding, 20 0 12);
            text-align: center;
            font-weight: 300;
        }
        .sub-title {
            @include pxRem(margin-bottom, 40);
        }
        .error-img {
            @include pxRem(max-width, 200);
            @include pxRem(min-width, 100);
            display: block;
            margin: auto;
        }
        .not-sesamin-user-img {
            @include pxRem(max-width, 300);
            @include pxRem(min-width, 100);
            display: block;
            margin: auto;
        }
        .btn {
            @include pxRem(margin, 40 0);
            @include pxRem(max-width, 300);
            width: 100%;
        }
    }
    .not-found-footer {
        @include pxRem(margin, 0 -50);
        position: relative;
    }
    .sub-title-not-sesamin {
        font-size: 2.2rem;
        font-weight: 100!important;
    }
}
.page-not-sesamin {
    .not-found-body {
        margin-top: 50px;
        .sub-title-not-sesamin {
            @include pxRem(font-size, 18);
        }
    }
}
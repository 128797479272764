form {
  font-size: map-get($font-size, m);
  .form-label {
    display: flex;
    margin-bottom: .5rem;
    .form-label-badge {
      display: inline-block;
      color: map-get($color, white);
      padding: 0 5px;
      border-radius: 8px;
      background-color: map-get($color, border-gray);
      margin-right: 12px;
      height: 26px;
    }
    .form-label-text {
      flex: 1;
    }
    &.required {
      .form-label-badge {
        background-color: map-get($color, orange-b);
      }
    }
  }
  .form-control,
  input,
  textarea,
  .select-show {
    @include pxRem(padding, 16 12);
    display: block;
    width: 100%;
    border: 3px solid map-get($color, black-b);
    border-radius: 8px;
    box-shadow: 2px 2px map-get($color, blue-d);
    font-size: map-get($font-size, l);
    font-family: $font-japan;
    color: map-get($color, black);
    &.form-control-lg {
      @include pxRem(height, 184);
    }
    &.form-control-md {
      @include pxRem(height, 132);
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: map-get($color, grey-j);
      opacity: 1; /* Firefox */
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: map-get($color, grey-j);
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: map-get($color, grey-j);
    }
  }

  select {
    opacity: 0;
    position: absolute;
  }

  .select-show {
    &.placeholder {
      color: map-get($color, grey-j);
    }
  }

  .error-msg {
    display: inline-block;
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
    background-color: map-get($color, primary);
    font-size: map-get($font-size, m);
  }

  .select-wrapper {
    position: relative;

    &.has-arrow {
      &::after {
        @include pxRem(right, 16);
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid map-get($color, black);
      clear: both;
      pointer-events: none;
      }
    }

    &.loading {
      &::before {
        content: '';
        background: rgba(0, 0, 0, 0.1);
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        border-radius: 8px;
      }
    }
  }

}

.txt-prewarp {
  white-space: pre-wrap;
}
.txt-left {
  text-align: left;
}
.txt-right {
  text-align: right;
}
.txt-center {
  text-align: center
}
.no-wrap {
  white-space: nowrap;
}
.txt-bold {
  font-weight: 700;
}
.txt-demi {
  font-weight: 600;
}
.txt-medi {
  font-weight: 500;
}
.txt-nor {
  font-weight: 400;
}
.txt-uppercase {
  text-transform: uppercase;
}
.txt-capitalize {
  text-transform: capitalize;
}
.txt-xxs {
  font-size: map-get($font-size, xxs);
}
.txt-s {
  font-size: map-get($font-size, s);
}
.txt-m {
  font-size: map-get($font-size, m);
  @media (max-width: 420px) {
    font-size: map-get($font-size, s);
  }
}
.txt-l {
  font-size: map-get($font-size, l);
  @media (max-width: 420px) {
    font-size: map-get($font-size, s);
  }
}
.txt-muted {
  font-size: map-get($font-size, s);
  color: map-get($color, grey-j);
}
.txt-link {
  font-size: map-get($font-size, m);
  color: map-get($color, blue-e);
  text-decoration: underline;
}

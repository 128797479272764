.favorites-page {
  @include pxRem(padding-top, 16);
  @include pxRem(padding-bottom, 105);
  background: map-get($color, primary);

  .page-title {
    @include pxRem(margin-bottom, 16);
    font-weight: 500;
    line-height: 1.4;
    padding: 0;
  }

  .guide-group {
    @include pxRem(margin, 16 0 20);
    color: map-get($color, black-b);

    .guide {
      @include pxRem(margin-bottom, 8);
      line-height: 2;
    }

    .example {
      @include pxRem(font-size, map-get($font-size, s));
      color: map-get($color, grey-j);
    }
  }

  .favorites-section {
    @include pxRem(border-radius, 12);
    @include pxRem(padding, 16);
    background: map-get($color, white);

    .section-header {
      @include pxRem(border-bottom, dashed 1 map-get($color, grey-b));
      @include pxRem(padding-bottom, 10);
      display: flex;
      align-items: flex-start;

      .title-group {
        flex: 1;
      }

      .sub-title {
        @include pxRem(font-size, map-get($font-size, s));
        color: map-get($color, grey-j);
      }
      
      .title {
        @include pxRem(font-size, map-get($font-size, l));
        font-weight: 500;
        display: inline-block;
        position: relative;

        &::after {
          @include pxRem(height, 4);
          @include pxRem(bottom, -4);
          content: "";
          background: map-get($color, blue-d);
          position: absolute;
          right: 0;
          left: 0;
        }
      }

      .img-wrap {
        @include pxRem(width, 92);
        @include pxRem(margin-right, 12);
        border-radius: 12px;
      }
    }

    .section-action {
      @include pxRem(padding, 16 60);
      position: fixed;
      bottom: 0;
      max-width: 100vw;
      width: 100%;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
  
      .btn-back {
        @include pxRem(min-width, 200);
        @include pxRem(max-width, 300);
        width: 100%;
      }
    }
  }

  .favorite {
    @include pxRem(border, solid 1 map-get($color, grey-j));
    @include pxRem(border-radius, 12);
    &.overlay {
      position: relative;
      &::after {
        @include pxRem(border-radius, 12);
        background-color: rgba(0, 0, 0, 0.15);
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    &-list {
      .list-item {
        @include pxRem(padding-bottom, 20);
      }
    }

    &-content {
      @include pxRem(padding, 8);
      @media (max-width: 320px) {
        @include pxRem(padding-top, 0);
      }
    }

    .days-of-week {
      @include pxRem(padding, 10);
      @include pxRem(border-bottom, solid 1 map-get($color, grey-j));
      @include pxRem(border-radius, 12 12 0 0);
      @include pxRem(font-size, map-get($font-size, m));
      font-weight: 500;
      line-height: 1;
      background: map-get($color, grey-l);
      text-align: center;
    }

    .information-group {
      @include pxRem(border-radius, 0 0 12 12);
      @include pxRem(padding, 0 16);
      display: flex;
      border-top: 0;

      @media (max-width: 320px) {
        @include pxRem(padding, 0);
      }

      &.empty {
        @include pxRem(padding, 20 36);
        display: flex;
        justify-content: center;
        align-items: center;
        
        .add-img {
          @include pxRem(height, 24);
          @include pxRem(width, 24);
          @include pxRem(margin-right, 12);
        }
      }

      .img-wrap {
        @include pxRem(height, 68);
        @include pxRem(width, 68);
        @include pxRem(margin, 9 16 0 0);
        border-radius: 12px;
        display: flex;
        align-items: center;
        @media (max-width: 320px) {
          @include pxRem(margin-right, 10);
        }
      }

      .action-content {
        line-height: 2;
        color: map-get($color, black-b);
        flex: 1;
      }
    }

    .delete-group {
      @include pxRem(font-size, map-get($font-size, s));
      color: map-get($color, black-b);
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .trash-img {
        @include pxRem(height, 16);
        @include pxRem(width, 16);
      }

      .delete-txt {
        color: map-get($color, grey-j);
      }
    }
  }
}

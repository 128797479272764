.alert-section {
  position: fixed;
  bottom: 83px;
  left: 0;
  right: 0;
  overflow: hidden;
  height: auto;
  .alert {
    width: 100%;
    height: 34px;
    opacity: 1;
    padding: 5px;
    text-align: center;
    background-color: map-get($color, blue-d);
    animation-name: alert-on;
    animation-duration: 400ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    + .alert {
      margin-top: 1px;
    }
  }
}

@keyframes alert-on {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 34px;
    opacity: 1;
  }
}

.reminder-setting-page {
  @include pxRem(padding, 16);
  min-height: 100vh;
  background: map-get($color, primary);

  .page-title {
    @include pxRem(padding, 0 0 16);
    font-weight: 400;
  }

  .reminder-form {
    @include pxRem(padding, 16);
    background-color: map-get($color, white);
    border-radius: 12px;
    position: relative;
    .guide {
      text-align: justify;
    }
    .title-group {
      display: flex;
      align-items: center;
      .icon {
        @include pxRem(height, 28);
        @include pxRem(width, 28);
        @include pxRem(margin-right, 6);
      }
      .title {
        @include pxRem(font-size, map-get($font-size, xl));
        font-weight: 300;
      }
    }
    .setting-group {
      @include pxRem(padding, 16);
      @include pxRem(margin-bottom, 24);
      border: solid 2px map-get($color, blue-d);
      border-radius: 12px;
      box-shadow: 0 4px 0 0 map-get($color, blue-d);
      position: relative;

      .title-group {
        @include pxRem(margin-bottom, 16);
        position: relative;
        z-index: 1;
      }
      &:focus-within {
        background: map-get($color, primary);
        border-color: map-get($color, primary);
        transition: all .3s;
      }
      .alarm-icon {
        @include pxRem(width, 80);
        @include pxRem(top, 15);
        @include pxRem(right, 15);
        position: absolute;
      }
    }
    .note {
      @include pxRem(padding-bottom, 18);
      @include pxRem(font-size, map-get($font-size, s));
      color: map-get($color, orange-b);
      font-weight: 700;
    }
  }

  .selects-group {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .select-show {
      @include pxRem(width, 65);
      @include pxRem(font-size, map-get($font-size, xxl));
      background: map-get($color, white);
      font-family: $font-number;
      line-height: 1;
      text-align: center;
      border-radius: 12px;
      margin: 0;
    }

    select {
      &:focus {
        & + .select-show {
          border: solid 3px map-get($color, blue-f);
          outline: none;
        }
      }
    }

    option {
      text-align: center;
    }

    .select-wrapper {
      &:first-child {
        @include pxRem(margin-right, 16);
        .select-show {
          width: auto;
          font-weight: 600;
        }
      }
    }

    .time-symbol {
      @include pxRem(margin, 0 8 0 10);
      @include pxRem(font-size, map-get($font-size, 4xl));
      position: relative;
      transform: translateY(-5px);
      font-weight: 700;
    }
  }

  .action-notice {
    $size: map-get($font-size, l);
    @include pxRem(padding, 10 20);
    @include pxRem(border-radius, 8);
    font-size: $size;
    font-weight: 400;
    text-decoration: none;
    background-color: map-get($color, yellow-a);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 30px;
    .icon {
      @include pxRem(height, $size);
      @include pxRem(width, $size);
      @include pxRem(margin-right, 10);
      position: absolute;
      transform: translate(-50%, -50%);
      left: 8%;
      top: 52%;
    }
  }

  .action-note {
    color: map-get($color, grey-j);
    text-align: justify;
  }

  .change-product {
    .body-products {
      border-radius: 12px;
      background-color: map-get($color, grey-a);
      .item {
        justify-content: center;
        padding: 8px 0;
        img {
          width: 50%;
          max-width: 100px;
        }
        .item-name {
          width: 50%;
          padding: 0 8px;
          text-align: center
        }
      }
    }
  }

  .fixed-section {
    @include pxRem(padding, 16);
    height: auto;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    .submit-btn {
      width: 100%;
    }
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}
html, body {
  font-size: map-get($font-size, m);
  font-family: $font-japan;
  position: relative;
  font-weight: 400;
  color: map-get($color, black-b);
  line-height: 1.5;
  letter-spacing: 0.8px;
  background-color: map-get($color, white);
  overscroll-behavior: contain;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  padding: 0;
  @media (max-width: 360px) {
    font-size: map-get($font-size, xs);
  }
}

body {
  &.modal-open {
    overflow: hidden;
  }
}

p {
  font-size: map-get($font-size, m);
}

img {
  max-width: 100%;
  width: 100%;
}

a {
  color: map-get($color, blue-e);
}

#root {
  white-space: pre-line;
  &.limit-height {
    height: 100vh;
    overflow: hidden;
  }
}

.container {
  @include pxRem(padding, 0 16);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.flex-center-x {
  display: flex;
  justify-content: center;
}

.flex-center-y {
  display: flex;
  align-items: center;
}

.d-none {
  display: none;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex;
}

.p-relative {
  position: relative;
}

.pages-container {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  min-height: 100vh;
  max-width: 100vw;
  width: 100%;
  position: relative;
}

.bg-blue {
  background-color: map-get($color, blue-d);
}

.lh-max {
  line-height: 2;
}

.mx-auto {
  margin: 0 auto;
}

.mb-65 {
  margin-bottom: 65px;
}

.txt-l.fs-18 {
  font-size: 18px!important;
}

.font-weight-bold {
  font-weight: 600;
}
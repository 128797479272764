.done-warning-page {
  .warning-section {
    @include pxRem(padding-top, 20);
    text-align: center;
  }
  .warning-txt {
    font-size: map-get($font-size, l);
    font-weight: 500;
  }
}

.history-page {
  @include pxRem(padding-top, 16);
  @include pxRem(padding-bottom, 16);
  background-color: map-get($color, primary);
  min-height: 100vh;

  .section-content {
    background-color: map-get($color, white);
    border-radius: 10px;
  }
  .section-title {
    @include pxRem(padding, 20 16 5);
  }

  .dashboard {
    @include pxRem(padding-top, 16);
  }

  .chart-group {
    @include pxRem(margin, 0 16);
  }

  .bubble-group {
    @include pxRem(border-width, 2 2 5);
    @include pxRem(border-radius, 10);
    @include pxRem(padding, 16);
    @include pxRem(margin-bottom, 20);
    @include pxRem(line-height, 20);
    color: map-get($color, black-b);
    border-color: map-get($color, blue);
    border-style: solid;
    position: relative;

    .bubble-content {
      @include pxRem(font-size, map-get($font-size, s));
      @media (max-width: 320px) {
        @include pxRem(font-size, map-get($font-size, xs));
      }
    }

    .day-info {
      display: flex;
      align-items: baseline;
    }

    .diff-days-group {
      @include pxRem(font-size, map-get($font-size, m));
      @include pxRem(margin-left, 4);
      font-weight: 500;
      @media (max-width: 320px) {
        @include pxRem(font-size, map-get($font-size, s));
      }
    }

    .diff-days {
      @include pxRem(font-size, map-get($font-size, l));
      font-weight: 600;
      color: map-get($color, orange-b);
      @media (max-width: 320px) {
        @include pxRem(font-size, 18);
      }
    }
  }

  .canvas-wrap {
    position: relative;
  }

  .chart-tick {
    position: absolute;
    transform: translateX(-50%);
  }

  .chart-memo-group {
    @include pxRem(font-size, map-get($font-size, xs));
    color: map-get($color, grey-j);
    text-align: end;

    .memo {
      @include pxRem(font-size, map-get($font-size, xs));
      display: inline-flex;
      align-items: center;
      line-height: 1;
      &:before {
        @include pxRem(height, 16);
        @include pxRem(width, 4);
        @include pxRem(margin-right, 5);
        content: '';
        display: inline-block;
      }
    }
    .drinking-memo {
      @include pxRem(padding-right, 8);
      &:before {
        background-color: map-get($color, blue);
      }
    }
    .action-memo {
      &:before {
        background-color: map-get($color, primary);
      }
    }
  }

  .ribbon {
    @include pxRem(padding, 8 16);
    @include pxRem(margin-top, -8);
    position: relative;
    &-img {
      display: block;
    }
    &-title {
      font-weight: 500;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .badge-section {
    @include pxRem(padding, 16 16);
    display:flex;
    justify-content: space-between;
    @include pxRem(gap, 16);
    .badge-item {
      background-color: map-get($color, grey-e);
      flex: 1;
      @include pxRem(padding, 4);
      .badge-title {
        @include pxRem(font-size, map-get($font-size, xs));
      }
      .badge-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1;
        @include pxRem(padding-top, 2);
        .badge-icon {
          box-sizing: border-box;
          @include pxRem(width, 20);
          @include pxRem(height, 20);
        }
        .badge-text{
          .badge-number {
            @include pxRem(font-size, map-get($font-size, l));
            @include pxRem(padding-right, 4);
          }
          .badge-unit {
            @include pxRem(font-size, map-get($font-size, xs));
            position: relative;
            @include pxRem(bottom, 1);
          }
        }
      }
    }
  }

  .history-item {
    @include pxRem(padding, 16);
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px map-get($color, border-light);
    &:last-child {
      border-bottom: none;
    }
    .history-date {
      padding-right: 20px;
      width: 30%;
      color: map-get($color, grey-j);
      line-height: 1;
      .date-text {
        @include pxRem(margin-bottom, 4);
      }
      .section-text {
        @include pxRem(margin, 8 0 4);
      }
    }
    .history-detail {
      @include pxRem(margin-bottom, -8);
      width: 70%;
      flex-grow: 1;
      .action-submited {
        @include pxRem(padding-bottom, 12);
      }
      .start-plan {
        margin-bottom: 8px;
      }
      .badges {
        .badge-item {
          @include pxRem(padding-bottom, 8);
          display: flex;
          align-items: flex-start;
          line-height: 1;

          .icon {
            @include pxRem(width, 24);
            @include pxRem(margin-right, 8);
            height: 100%;
          }
          span {
            line-height: 25px;
          }
        }
      }
      .change-product {
        gap: 0.5rem;
        .image {
          .icon { 
            width: 1.5rem;
            height: auto
          }
        }
        .text {
          flex: 1;
          white-space: normal;
          line-height: 1.3;
        }
      }
    }
  }

  .logging-list {
    @include pxRem(margin-bottom, 15);
    &:last-child {
      margin-bottom: 0;
    }
    .selected-list {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 8px;
    }
  }
  .selected-list {
    @include pxRem(padding, 8);
    background-color: map-get($color, grey-a);
    li {
      @include pxRem(padding-bottom, 8);
      &::before {
        content: '・';
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .bubble-arrow {
    @include pxRem(border-left, 8 solid transparent);
    @include pxRem(border-right, 8 solid transparent);
    @include pxRem(border-top, 17 solid map-get($color, blue));
    @include pxRem(bottom, -18);
    position: absolute;
    transform: translateX(-50%);
    &::before {
      content: '';
      @include pxRem(border-left, 5 solid transparent);
      @include pxRem(border-right, 5 solid transparent);
      @include pxRem(border-top, 11 solid map-get($color, white));
      @include pxRem(top, -19);
      position: absolute;
      transform: translateX(-50%);
    }
  }

  .tabs-group {
    @include pxRem(margin-top, 10);
  }
  .tab-header-item {
    @include pxRem(padding, 5 16 12);
    
    &.active {
      @include pxRem(padding-top, 7);
      @include pxRem(padding-bottom, 17);
      background: map-get($color, white);
    }
  }

  .tab-content {
    @include pxRem(border-radius, 10);
  }

  .actions-complete-list {
    .list-item {
      @include pxRem(border-bottom, solid 1 map-get($color, grey-i));

      &:last-child {
        border-bottom: 0;
      }
    }

    .actions-complete {
      display: flex;
      @include pxRem(padding, 16);

      .img-wrap {
        @include pxRem(width, 92);
        @include pxRem(height, 92);
        @include pxRem(margin-right, 14);
        display: flex;
        align-items: center;
      }

      .content {
        flex: 1;
      }

      .title-group {
        display: flex;
        align-items: flex-start;
          .category-name {
            @include pxRem(margin-right, 8);
            @include pxRem(border-radius, 4);
            @include pxRem(padding, 4);
            font-size: map-get($font-size, s);
            line-height: 1.2;
            position: relative;
            text-align: center;
          }
        .title {
          @include pxRem(line-height, 20);
          @include pxRem(padding-top, 2);
          color: map-get($color, blue-e);
          text-decoration: underline;
          flex: 1;
        }
      }

      .action-btn {        
        @include pxRem(margin-top, 16);
        @include pxRem(border-radius, 26);
        font-size: map-get($font-size, s);
        background: map-get($color, yellow-a);
        width: 100%;
        border: none;
        box-shadow: none;
      }
    }
  }

  .empty-group {
    @include pxRem(padding, 16);
    @include pxRem(min-height, 100);
    line-height: 2;
  }
}

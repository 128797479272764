.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  outline: 0;
  animation-name: modal-on;
  animation-duration: 400ms;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: map-get($color, black);
    opacity: .5;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    z-index: 100;
    background: white;
    border-radius: 8px;
    width: 80%;
    text-align: center;
    font-weight: 500;
    .modal-header {
      padding: 20px 16px;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 1rem;
        font-weight: bold;
      }
    }
    .modal-footer {
      padding: 20px 16px;
      border-top: 1px solid map-get($color, grey-i);
    }
    .modal-body {
      padding: 20px 16px;
    }
    .modal-close-button {
      cursor: pointer;
      font-size: 2rem;
      font-weight: 700;
      line-height: 1;
      color: map-get($color, black);;
      opacity: .3;
      border: none;
      padding: 0;
      background-color: transparent;
    }
    button, .btn {
      padding: 6px 18px;
      box-shadow: none;
    }
  }
  .gift {
    .receive-btn {
      @include pxRem(padding, 12 32);
      width: 100%;
    }
    .date {
      color: map-get($color, orange-b);
    }
    .note {
      color: map-get($color, grey-j);
    }
  }
}

@keyframes modal-on {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

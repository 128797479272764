.result-page {
  .chart-area {
    position: relative;
    .chart {
      @include pxRem(margin, 0 30);
      position: absolute;
      top: 22%;
      left: 0;
      width: calc(100% - 60px);
    }
  }
  .page-title {
    font-weight: 400;
  }
  &.second-time {
    .chart-area {
      .chart {
        top: 18%;
      }
    }
  }

}

.last-page-error {
  position: absolute;
  text-align: center;
  width: 85%;
  top: calc((100vh - 135px)/2);
  left: 50%;
  transform: translate(-50%, -50%);
  .not-sesamin-user-img {
    @include pxRem(max-width, 300);
    @include pxRem(min-width, 100);
    display: block;
    margin: auto;
  }
  .action-move-question {
    $size: map-get($font-size, l);
    @include pxRem(padding, 10 20);
    @include pxRem(border-radius, 8);
    font-size: $size;
    font-weight: 400;
    text-decoration: none;
    background-color: map-get($color, yellow-a);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 30px;
  }
}

@media (min-width: map-get($breakpoints, medium)) {
  .result-page {
    .chart-area {
      max-width: 650px;
      margin: 0 auto;
      .chart {
        top: 25%;
      }
    }
  }
}

.position-relative {
  position: relative !important;
}
.feedback-page {
  .introduction {
    .title {
      @include pxRem(font-size, map-get($font-size, l));
    }
  }

  .questionnaire-form {
    .page-title {
      @include pxRem(padding, 24);
      font-weight: 400;
    }
  }
}

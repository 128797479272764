.product-page {
  @include pxRem(padding, 0 32 135 32);
  .body-note {
    color: map-get($color, orange-b);
    font-weight: 700;
  }
  .item {
    width: 100%;
    display: flex;
    flex-direction: row; 
    background-color: map-get($color, grey-k);
    @include pxRem(gap, 8);
    @include pxRem(border-radius, 10);
    @include pxRem(padding, 16);
    &.active {
      box-shadow: 2px 4px 0 map-get($color, blue-d);
      background-color: map-get($color, yellow-a);
    }
    .item-img {
      @include pxRem(width, 100);
      @include pxRem(border-radius, 10);
    }
    .item-name {
      flex: 1;
      text-align: center;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.8;
    }
  }
  .body-products {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    @include pxRem(gap, 16);
    @include pxRem(margin-bottom, 8);
    .item-name.txt-l{
      @media (max-width: 500px) {
        font-size: map-get($font-size, m);
      }
      @media (max-width: 360px) {
        font-size: map-get($font-size, xs);
      }
    }
  }
  .fixed-section {
    @include pxRem(padding, 16);
    height: auto;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    .btn-cancel {
      width: 100%;
    }
  }
}

.tabs-group {
  .tabs-header-list {
    @include pxRem(bottom, -7);
    position: relative;
    display: flex;
    align-items: flex-end;
  }

  .tabs-content-list {
    position: relative;
  }

  .tab-header-item,
  .tab-content {
    @include pxRem(border-top-left-radius, 10);
    @include pxRem(border-top-right-radius, 10);
  }

  .tab-content-item {
    display: none;
  }
  
  .tab-content {
    background: map-get($color, white);
  }

  .tab-header-item {
    background: map-get($color, grey-k);
    color: map-get($color, grey-j);
    width: 100%;
    text-align: center;

    &.active {
      color: map-get($color, black-b);
    }
  }

  &.has-border {
    .tab-header-item {
      @include pxRem(border, solid 1  map-get($color, grey-b));
      border-bottom: 0;
      position: relative;

      &.active {
        .border-hide {
          @include pxRem(height, 10);
          @include pxRem(bottom, -2);
          position: absolute;
          right: 0;
          left: 0;
          z-index: 1;
        }
      }
    }

    .tab-content {
      @include pxRem(border, solid 1 map-get($color, grey-b));
    }
  }
}

.questionnaire-page {
  .page-title {
    @include pxRem(padding, 16);
    font-weight: 400;
  }

  .introduction {
    .content {
      @include pxRem(line-height, 32);
      @include pxRem(padding, 20 0);
      font-weight: 400;
      line-height: 2;
    }

    .questions-info {
      color: map-get($color, grey-j);
    }
  }
}

.registered-gift {
  .page-title {
    @include pxRem(padding, 16);
  }
  .confirm-content {
    @include pxRem(padding, 0 32);
    .content-item {
      @include pxRem(padding-bottom, 28);
    }
    .item-label {
      @include pxRem(padding, 8);
      background-color: map-get($color, grey-l);
      border-bottom: 1px solid map-get($color, black-b);
      border-radius: 0;
    }
    .content-img {
      @include pxRem(padding-top, 16);
      .img-block {
        @include pxRem(width, 80);
        @include pxRem(height, 80);
        background-color: map-get($color, grey-l);
      }
    }
    .content-text {
      @include pxRem(padding-top, 16);
    }
    .highlight-text {
      @include pxRem(font-size, 15);
      color: map-get($color, orange-b);
      font-weight: 700;
    }
    .message {
      border-radius: 7px;
    }
  }
}

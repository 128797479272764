$taken-height: 147px;

.action-random-list-page {
  .tab-header-item {
    @include pxRem(padding, 8 8 12);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: map-get($color, black-b);
    &:nth-child(-n+2) {
      @include pxRem(margin-right, 2);
    }
    &.active {
      .name {
        @include pxRem(margin, 4 0);
      }
    }
    
    .name {
      @include pxRem(font-size, map-get($font-size, xl));
      font-weight: 300;
    }
  }
  .tabs-content-list {
    position: relative;
    min-height: calc(100vh - #{$taken-height});
  }

  
  .tab-content {
    @include pxRem(padding, 16);
    border-radius: 0;
    min-height: inherit;
    
    &-item {
      min-height: inherit;
    }
  }

  .advice {
    @include pxRem(margin-bottom, 16);
    display: flex;
    justify-content: space-between;
    .img-wrap {
      @include pxRem(margin-top, 19);
      @include pxRem(height, 60);
      @include pxRem(min-width, 60);
    }

    .adviser {
      @include pxRem(font-size, map-get($font-size, xs));
      @include pxRem(margin-bottom, 4);
    }
    &-content-group {
      @include pxRem(margin-left, 20);
      margin-right: auto;
    }
    &-content {
      @include pxRem(font-size, map-get($font-size, s));
      @include pxRem(padding, 8);
      @include pxRem(border-radius, 10);
      @include pxRem(border, solid 1 map-get($color, grey-b));
      position: relative;
      background: map-get($color, white);
      &:after, &:before {
        @include pxRem(top, 29);
        content: "";
        right: 100%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        @include pxRem(border-width, 4 9 4 0);
        @include pxRem(margin-top, -4);
        border-color: transparent;
        border-right-color: map-get($color, white);
        border-style: solid;
      }
      &:before {
        @include pxRem(border-width, 5 11 5 0);
        @include pxRem(margin-top, -5);
        border-color: transparent;
        border-right-color: map-get($color, grey-b);
        border-style: solid;
      }
      &.one-line {
        @include pxRem(top, 13);
        &:after, &:before {
          @include pxRem(top, 50%);
        }
      }
    }
  }
  .lession {
    display: flex;
    justify-content: space-between;
    &-img {
      @include pxRem(height, 124);
      @include pxRem(width, 124);
    }
    &-content {
      @include pxRem(margin-right, 16);
    }
  }
  .redirect-btn {
    @include pxRem(padding, 12);
    @include pxRem(margin-top, 20);
    width: 100%;
  }
  .empty-txt {
    text-align: center;
  }
}

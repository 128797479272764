$items-per-line : 3;
.badges-page {
  @include pxRem(padding-top, 16);
  background-color: map-get($color, primary);
  min-height: 100vh;

  .page-title {
    @include pxRem(padding, 0 0 16);
    line-height: 1;
  }

  .section-badge-tabs {
    .badge-tabs-list {
      @include pxRem(margin, 0 -4);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .tab-item {
        @include pxRem(padding, 0 4 8);
        width: calc(100% / #{$items-per-line});
        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }
        .btn {
          @include pxRem(padding, 10);
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 360px) {
            display: block;
            width: 100%;
            text-align: center;
          }
          &.has-gift {
            position: relative;
            &:after {
              @include pxRem(width, 10);
              @include pxRem(height, 10);
              @include pxRem(top, -3);
              @include pxRem(right, -3);
              background-color: map-get($color, blue-f);
              box-shadow: 0 1px 1px 0 map-get($color, grey-j);
              content: '';
              position: absolute;
              border-radius: 50%;
            }
          }
          .badge-icon-small {
            @include pxRem(margin-right, 10);
            @include pxRem(width, 28);
            height: 100%;
            @media (max-width: 360px) {
              margin-right: 0;
              width: 2.75rem;
            }
          }
          .tab-item-text {
            @include pxRem(font-size, map-get($font-size, s));
            @include pxRem(width, 30);
            line-height: 1;
            align-self: right;
            @media (min-width: 411px) {
              min-width: 60px;
            }
            @media (max-width: 360px) {
              width: 100%;
              font-size: 1rem;
            }
          }
        }
        .active {
          background-color: map-get($color, blue-b);
          border-color: map-get($color, blue-c);
        }

      }
    }
  }

  .section-badge-content {
    @include pxRem(margin-top, 12px);
    @include pxRem(padding-bottom, 24);
    background-color: map-get($color, white);
    border-radius: 16px;

    .title-wrap {
      @include pxRem(padding, 16);
      @include pxRem(padding-bottom, 24);
      line-height: 1;
    }

    .content-title {
      position: relative;
      font-weight: 500;

      &::after {
        @include pxRem(height, 4);
        @include pxRem(bottom, -4);
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        background-color: map-get($color, blue);;
      }
    }

    .badges-list {
      @include pxRem(padding, 0 8);
      display: flex;
      flex-wrap: wrap;
      .badge-item {
        @include pxRem(padding, 0 2 8);
        width: calc(100% / #{$items-per-line});
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 411px) {
          @include pxRem(padding, 0 4 8);
        }
        .icon-wrap {
          @include pxRem(margin-bottom, 12);
          font-size: 0;
          position: relative;
        }
        .icon {
          @include pxRem(width, 88);
          height: 100%;
        }
        .gift {
          @include pxRem(bottom, 5);
          @include pxRem(right, 10);
          position: absolute;
          img {
            @include pxRem(width, 35);
            height: auto;
            &.disabled {
              @include pxRem(width, 27);
              @include pxRem(margin-bottom, -5);
            }
          }
          .giftAnimation {
            @include pxRem(width, 52);
            height: auto;
            @include pxRem(margin-right, -10);
            @include pxRem(margin-bottom, -10);
          }
        }
        .title {
          @include pxRem(padding, 6 8 10);
          @include pxRem(max-width, 104);
          background-color: map-get($color, grey-l);
          flex: 1;
        }
        .title-achieved {
          @include pxRem(max-width, 94);
        }
        .date-achieved {
          @include pxRem(padding, 4 7);
          @include pxRem(font-size, map-get($font-size, s));
          color: map-get($color, grey-d);
        }
      }
    }
  }
}

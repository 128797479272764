.logging-page {
  .title {
    text-align: center;
    font-weight: bold;
    font-size: map-get($font-size, l);
  }
  .highlight-number {
    font-size: map-get($font-size, xxxl);
    font-weight: 700;
    color: map-get($color, orange-b);
  }

  .btn-confirm {
    width: 100%;
  }

  .hint {
    @include pxRem(font-size, map-get($font-size, s));
  }
  .logging-list {
    @include pxRem(padding-bottom, 24);
  }
  .logging-item {
    @include pxRem(border-bottom, solid 1 map-get($color, white));
    position: relative;
    &.special {
      @include pxRem(border-top, solid 1 map-get($color, border-gray));
      @include pxRem(margin-top, -1);
    }
    &.checked {
      background-color: map-get($color, primary);
    }
    &.disabled {
      &:after {
        content: '';
        background: rgba(0, 0, 0, 0.12);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}

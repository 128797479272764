.kind-page {
  .register-selection {
    .dot {
      @include pxRem(min-width, 20);
      @include pxRem(height, 20);
      @include pxRem(margin-right, 13);
      @include pxRem(margin-top, 5);
      display: inline-block;
      border-radius: 50%;
      background-color: map-get($color, blue-d);
      &::after {
        @include pxRem(width, 4);
        @include pxRem(top, 5);
        @include pxRem(transform, translateX(8));
        content: '';
        transition: all .3s ease;
        display: inline-block;
        height: 0;
        background-color: map-get($color, blue-d);
        position: absolute;
        left: 0;
        transform: translateX(8px);
      }
      &.active {
        &::after {
          height: calc(100% - 5px);
        }
      }
    }

    .small-txt {
      @include pxRem(margin-top, 2);
      color: map-get($color, grey-j);
      font-weight: 500;
    }

    .collapse-btn {
      @include pxRem(padding, 4 16);
      @include pxRem(width, 140);
      @include pxRem(border-radius, 42);
      font-size: map-get($font-size, m);
      border: none;
      background-color: map-get($color, grey-i);
      box-shadow: none;
      .icon {
        @include pxRem(right, 12);
      }
    }
    input {
      @include pxRem(padding, 10 16);
      @include pxRem(font-size, map-get($font-size, l));
    }
  }
  .register-area {
    @include pxRem(padding, 16);
    border: solid 2px map-get($color, blue);
    border-radius: 20px;
    box-shadow: 0 3px map-get($color, blue-d);
    &.error {
      border-color: map-get($color, orange-b);
      box-shadow: 0 3px map-get($color, orange-b);
    }
  }
  .info-icon {
    @include pxRem(width, 24);
    @include pxRem(height, 24);
  }
  .forgot-pass-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .switch-form {
    @include pxRem(height, 84);
    text-align: center;
  }

  .accordion-btn {
    pointer-events: none;
    &.active {
      pointer-events: auto;
    }
    .icon {
      @include pxRem(right, 20);
    }
  }
  .collapse-btn,
  .accordion-btn {
    position: relative;
    .icon {
      @include pxRem(height, 9);
      @include pxRem(width, 13);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .term-policy {
    .term-checkbox {
      @include pxRem(border-radius, 25);
      border: 1px solid map-get($color, border-gray);
      &.checked {
        background-color: map-get($color, yellow-a);
        border: 1px solid map-get($color, yellow-a);
      }
      .form-label {
        @include pxRem(font-size, 15);
      }
    }
    span {
      @include pxRem(margin-left, 4);
      @include pxRem(margin-right, 4);
      @media (max-width: 360px) {
        white-space: nowrap;
      }
    }
    .mb-2 {
      @media (max-width: 360px) {
        text-align: center;
      }
    }
  }

}

footer {
  @include pxRem(padding, 25 0);
  @include pxRem(border-top, solid 1 map-get($color, grey-i));
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  text-align: center;
  .info {
    display: flex;
    justify-content: center;
    @include pxRem(padding-bottom, 24);
    font-size: map-get($font-size, m);
    .terms {
      color: map-get($color, blue-e);
      @include pxRem(padding, 0 20);
    }
    @media (max-width: 360px) {
      justify-content: space-around;
      .terms {
        padding: 0;
      }
    }
  }
  .copyright {
    font-size: map-get($font-size, xs);
  }
}

.has-footer {
  @include pxRem(padding-bottom, 180 !important);
  @media (max-width: 360px) {
    @include pxRem(padding-bottom, 200 !important);
  }
}

/* ビルド後のCSSファイル名を変更するために無意味なコードを挿入する（キャッシュをクリアする目的）22/4/13以降のリリースでこのコードは削除する */
.dont-use-this-class {
  padding: 0;
  margin: 0;
}

.badge-gift {
  .page-title {
    @include pxRem(padding, 24);
  }
  .answer-list {
    @include pxRem(margin-bottom, 83);
    @include pxRem(padding-top, 16);
  }
  .badge-gift-item {
    @include pxRem(padding, 28 0);
    @include pxRem(margin-bottom, 32);
    border: solid 2px map-get($color, grey-k);
    border-radius: 20px;
    box-shadow: 0 3px map-get($color, grey-k);
    &.checked {
      border-color: map-get($color, yellow-a);
      box-shadow: 0 3px map-get($color, yellow-a);
    }
  }
  .fixed-section {
    height: auto;
    @include pxRem(padding, 16);
  }
  .confirm-content {
    @include pxRem(padding, 0 32);
    .content-item {
      @include pxRem(padding-top, 28);
    }
    .item-label {
      @include pxRem(padding, 8);
      background-color: map-get($color, grey-l);
      border-bottom: 1px solid map-get($color, black-b);
      border-radius: 0;
    }
    .content-img {
      @include pxRem(padding-top, 16);
      display: flex;
      .img-block {
        @include pxRem(width, 80);
        @include pxRem(height, 80);
        background-color: map-get($color, grey-l);
      }
    }
    .content-text {
      @include pxRem(padding-top, 16);
    }
    .highlight-text {
      font-size: map-get($font-size, xs);
      color: map-get($color, orange-b);
      font-weight: 700;
    }
  }
  .message {
    border-radius: 7px;
  }

  &.no-footer {
    + footer {
      display: none;
    }
  }
}

.encouraging-page {
  @include pxRem(padding-top, 16);
  .content {
    @include pxRem(margin-bottom, 20);
    font-size: map-get($font-size, m);
    line-height: 2;
  }
  .close-btn {
    width: 100%;
  }
}

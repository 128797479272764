.loading-panel {
  background-color: map-get($color, white);
  z-index: map-get($z-index, loading);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.small {
    @include pxRem(border-radius, 10);
    position: absolute;
    background: rgba(0, 0, 0 , 0.3);
  }

  .center-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.maintenance-page {
  @include pxRem(padding, 0 32);
  .img-wrap {
    text-align: center;
    img {
      @include pxRem(width, 180);
      // @include pxRem(height, 247);
      @include pxRem(margin-top, 10);
    }
  }
  .content {
    @include pxRem(margin-top, 20);
    @include pxRem(line-height, 32);
  }
  .title {
    font-size: 24px;
  }
}

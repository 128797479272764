.checklist-group {
  @include user-select(none);
  display: inline-block;
  position: relative;
  width: 100%;

  .form-label {
    @include pxRem(padding, 16 16 16 45);
    @include pxRem(border-radius, 8);
    margin-bottom: 0;
    display: inline-block;
    width: 100%;

    &.txt-center {
      @include pxRem(padding-right, 45);
    }

    &.has-bg {
      background-color: map-get($color, grey-k);
    }
  }

  input {
    @include pxRem(left, -999);
    position: absolute;
    width: auto;

    &:checked {
      + .form-label {
        background-color: map-get($color, yellow-a);
      }

      ~ {
        /* When checked */
        .checkmark {
          &.checkbox {
            background-color: map-get($color, black);
            visibility: visible;

            /* Show the checkmark when checked */
            &:after {
              border-color: map-get($color, primary);
            }
          }

          &.radio {
            border-color: map-get($color, black);

            // Show dot inside circle
            &:after {
              @include pxRem(height, 10);
              @include pxRem(width, 10);
              background-color: map-get($color, black);
              content: "";
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    &:disabled {
      + .form-label {
        background-color: map-get($color, grey-k);
        color: map-get($color, grey-j);
      }
    }
  }

  /* Create a custom checkbox/radip */
  .checkmark {
    &.checkbox {
      // @include pxRem(top, 15);
      @include pxRem(height, 20);
      @include pxRem(width, 20);
      @include pxRem(left, 16);
      top: 50%;
      transform: translateY(-50%);
      position: absolute;;
      background-color: map-get($color, grey-i);
      border-radius: 50%;
      
      /* Create the checkmark/indicator (hidden when not checked) */
      &:after {
        @include pxRem(left, 7);
        @include pxRem(top, 3);
        @include pxRem(width, 4);
        @include pxRem(height, 10);
        @include pxRem(border-width, 0 2 2 0);
        content: "";
        position: absolute;
        border-color: map-get($color, white);
        border-style: solid;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    
    /* Create the circle when not check */
    &.radio {
      @include pxRem(left, 16);
      @include pxRem(height, 20);
      @include pxRem(width, 20);
      @include pxRem(border, solid 2 map-get($color, grey-i));
      top: 50%;
      transform: translateY(-50%);
      position: absolute;;
      border-radius: 50%;
    }
  }
  &.special {
    .checkmark {
      top: calc(50% + 8px);
    }
  }

  &.have-image {
    .form-label {
      @include pxRem(padding, 16);
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      .text {
        flex-grow: 1;
        align-self: center;
      }

      .option-image {
        @include pxRem(width, 56);
        @include pxRem(height, 56);
        display: inline-block;
      }
    }
  }
}

// == Breakpoints ===
$breakpoints: (
  small: 480px,
  medium: 768px,
  wide: 960px,
  large: 1024px,
  largest: 1280px,
  super-largest: 1441px
);

$color: (
  primary:  #FAEB55,
  orange-a: #a17143,
  orange-b: #F47920,
  grey-a: #f3f3f3,
  grey-b: #979797,
  grey-c: #EEEEEE,
  grey-d: #6F6F6F,
  grey-e: #F7F7F7,
  grey-f: #D8D8D8,
  grey-g: #343434,
  grey-h: #404040,
  grey-i: #D4D4D4,
  grey-j: #818181,
  grey-k: #EAEAEA,
  grey-l: #F5F5F5,
  black: #000000,
  black-b: #231815,
  white: #FFFFFF,
  border-gray: #B5B5B5,
  border-light: #DDDDDD,
  blue: #BBDEFC,
  blue-b: #DBEEFF,
  blue-c: #9AD0FF,
  blue-d: #BDDEFD,
  blue-e: #0E73DC,
  blue-f: #5BC2DC,
  yellow-a: #F9EA55,
);

$font-size: (
  xxs:  11px,
  xs:   12px,
  s:    14px,
  m:    16px,
  l:    20px,
  xl:   24px,
  xxl:  28px,
  xxxl: 32px,
  4xl:  36px
);

$z-index: (
  loading: 1000,
  not-found: 2000,
);

$font-body: san-serif;
$font-japan: 'Noto Sans TC', sans-serif;
$font-number: 'Oswald', sans-serif;

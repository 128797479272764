.action-page {
  .bg-section {
    font-size: 0;
  }
  .action-bg {
    width: 100%;
  }
  .content-section {
    @include pxRem(padding-bottom, 100);
  }
  .action-item {
    @include pxRem(padding, 20 0 8);
    .title {
      font-size: map-get($font-size, m);
    }
    .img-wrap {
      img {
        width: auto;
      }
    }
  }
}

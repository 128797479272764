@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
.slick-slider {
  button {
    border-radius: none;
    box-shadow: none;
    padding: 0;
  }
}
.slick-initialized {
  .slick-slide {
    img {
      @include pxRem(max-width, 200px);
      margin: auto;
    }
  }  
}

.slick-dots {
  li {
    @include pxRem(width, 16px);
    @include pxRem(height, 10px);
    margin: 0;
    button {
      width: 8px;
      height: 8px;
      margin-right: 4px;
      &::before {
        width: 8px;
        height: 8px;
        margin-right: 6px;
        font-size: 16px;
        position: unset;
        line-height: 10px;
      }
    }
  }
}

.accordion {
  &-content {
    @include pxRem(max-height, 9999);
    overflow: hidden;
    transition: all 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    &.collapsed {
      overflow: hidden;
      transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
      max-height: 0;
    }
  }
  &-btn {
    opacity: 0;
    transition: opacity .3s ease;
    position: relative;
    &.active {
      opacity: 1;
    }
  }
}

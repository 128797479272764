.contact-info {
  .phone {
    font-size: map-get($font-size, xxl);
  }
  .txt-l{
    @media (max-width: 360px) {
      font-size: map-get($font-size, m)!important;
    }
  }
}

.markdown-preview {
  @include pxRem(padding, 0 32 100);
  color: map-get($color, black-b);
  font-weight: 400;
  white-space: normal;

  > div {
    &:first-child {
      @include pxRem(margin, 0 -32 8);
    }
  }

  span {
    display: inline-block;
  }

  ol {
    list-style: decimal;
    list-style-position: inside;
  }

  .bubble-group {
    display: flex;
    align-items: flex-start;
    .speaker-img {
      @include pxRem(width, 72);
      @include pxRem(margin-right, 10);
    }
    .bubble-content {
      @include pxRem(padding, 15);
      @include pxRem(font-size, map-get($font-size, s));
      @include pxRem(line-height, 20);
      @include pxRem(border, solid 3);
      @include pxRem(border-radius, 20);
      @include pxRem(box-shadow, 2 2 0 0 map-get($color, blue-d));
      @include pxRem(min-height, 75);
      position: relative;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;

      &:after {
        @include pxRem(right, 10);
        @include pxRem(padding, 0 2);
        background: map-get($color, white);
        content: attr(data-note);
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
        font-weight: 600;
        font-family: $font-japan;
      }
    }
  }

  .heading {
    @include pxRem(font-size, 36);
    @include pxRem(line-height, 44);
    font-weight: 300;
  }
  
  .note {
    @include pxRem(font-size, map-get($font-size, s));
    @include pxRem(line-height, 20);
    color: map-get($color, grey-j);
  }

  .paragraph {
    @include pxRem(font-size, map-get($font-size, m));
    @include pxRem(line-height, 32);
  }
  
  .break {
    @include pxRem(border, 1 dashed map-get($color, grey-b));
  }

  .bubble-arrow {
    @include pxRem(width, 30);
    @include pxRem(top, 48);
    @include pxRem(left, -3);
    position: absolute;
    transform: translateX(-50%);
  }

  .margin {
    line-height: 0;
    visibility: hidden;
    width: 100%;
  }

  p,
  ol,
  .heading,
  .normal-img,
  .note,
  .bubble-group {
    @include pxRem(margin-bottom, 8);
  }

  ol,
  p {
    @include pxRem(line-height, 32);
  }

  hr {
    @include pxRem(margin, 0 0 8);
  }
  
  video {
    @include pxRem(margin-bottom, 10);
    width: 100%;
    height: 100%;
  }
}

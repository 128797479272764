.question-index {
  @include pxRem(margin, -8 0 12);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  .index {
    @include pxRem(width, 40);
    @include pxRem(height, 40);
    font-size: map-get($font-size, l);
    border-radius: 50%;
    background-color: map-get($color, blue-d);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .progress {
    @include pxRem(margin-left, 12);
    color: map-get($color, grey-j);
  }
}

.questionnaire-form {
  font-size: map-get($font-size, l);
  .question {
    @include pxRem(padding, 16 32);
    line-height: 1.6;
    background-color: map-get($color, grey-l);
    @media (max-width: 320px) {
      @include pxRem(padding, 16);
    }
  }
  .answer-list {
    @include pxRem(padding, 28 32 12);
    .special {
      @include pxRem(padding-top, 16);
      border-top: solid 1px map-get($color, border-gray);
    }
    @media (max-width: 320px) {
      @include pxRem(padding, 32 16 16);
      .form-label {
        @include pxRem(padding, 16 32);
      }
    }
  }
  .form-control {
    @include pxRem(padding, 10 16);
    font-size: map-get($font-size, l);
  }
  .feedback-form {
    @include pxRem(padding, 28 32 8);
    font-size: map-get($font-size, m);
  }
  .error-msg {
    &.top-error {
      @include pxRem(padding, 8 40);
      margin-bottom: 0;
    }
  }
}


#radar-chart {
  @media (max-width: 420px) {
    &.radar-wrap {
      @include pxRem(height, 360);
    }
  }
  @media (max-width: 360px) {
    &.radar-wrap {
      @include pxRem(height, 300);
    }
  }
}

.feedback-thank-page {
  .title {
    @include pxRem(margin, 16 0);
    @include pxRem(font-size, map-get($font-size, l));
  }

  .sub-title {
    @include pxRem(margin-bottom, 28);
  }

  .close-btn {
    @include pxRem(margin-bottom, 28);
    width: 100%;
  }

  .content {
    @include pxRem(line-height, 32);
  }

  .highlight-condition {
    color: map-get($color, orange-b);
    font-weight: 700;
  }
}

.label {
  font-size: map-get($font-size, s);
  padding: 4px 8px;
  border-radius: 20px;
  &::before {
    content: '';
    display: inline-block;
    width: 13px;
    height: 13px;
    background-repeat: no-repeat;
    background-size: 12px;
    position: relative;
    top: 1px;
    padding-right: 5px;
  }
  &-blue {
    background-color: map-get($color, blue);
    &::before {
      background-image: url('../assets/images/drinking-icon.png');
    }
  }
  &-yellow {
    background-color: map-get($color, primary);
    &::before {
      background-image: url('../assets/images/action-icon.png');
    }
  }
  img {
    width: 12px;
  }
}
